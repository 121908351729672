import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { endOfDay, format, getUnixTime, intervalToDuration, startOfDay, subDays } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import api from 'trellis:utilities/api'
import { showMessage, spacesToProperty } from 'trellis:utilities/general'

import { SmartTable } from '../_siteWide/table/SmartTable'
import { usePostAuthLayoutContext } from '../layouts/PostAuthLayout/context/PostAuthLayoutContext'
import {
  Message,
  MessageContextProvider,
  useMessageContext,
} from './context/messageContext'
import { MessageModal } from './messageModal/messageModal'
import { DateRange, rangePresets, rangeThemeConfig } from 'trellis:utilities/datePickerUtilities'
import { RangePicker } from '@vynedental/design-system'

import './messages.scss'
import dayjs from 'dayjs'

// the following types could be moved to a global shared types file \\
// NOTE: these types are the same across most grids
type MessageRequest = {
  startDate: Date | string
  endDate: Date | string
  range: number
  offset: number
  sortColumn: string
  sortDirection: string
  exactFilters: {}
  searchFilters: {}
  searchText: string
  ignored?: boolean
  messageIds?: number[]
  processAll?: boolean
}

type SortDirection = 'asc' | 'desc'

type DataFilters = {
  PageSize: number
  CurrentPage: number
  SortColumn: string
  SortDirection: SortDirection
  ExactFilters: {}
  SearchFilters: {}
  Search: string
  Config: {}
  Ignored: boolean
}

type MessagesState = {
  Key: number
  Data: Message[]
  OriginalData: Message[]
  Total: number
  Filters: DataFilters
  SelectAll: boolean
  SelectedIds: []
  SelectedGridKeys: []
}
// end of common types \\

const { Search } = Input
const store = window.localStorage

const Messages: FC = () => {
  const { setTotalUnreadMessages, totalUnreadMessages } =
    usePostAuthLayoutContext()
  const { dateFormat, message, setMessage } = useMessageContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [state, setState] = useState<MessagesState>({
    Key: 0,
    Data: [],
    OriginalData: [],
    Total: 0,
    Filters: {
      PageSize: 10,
      CurrentPage: 1,
      SortColumn: 'messageDate',
      SortDirection: 'desc',
      ExactFilters: {},
      SearchFilters: {},
      Search: '',
      Config: {},
      Ignored: false,
    },
    SelectAll: false,
    SelectedIds: [],
    SelectedGridKeys: [],
  })
  const [dateRange, setDateRange] = useState<DateRange>({
    dates: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
    numberOfDays: 7,
  })

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const selections: { key: string; onSelect: Function }[] = [
    {
      key: 'MarkAsHidden',
      onSelect: async () =>
        await handleAction(spacesToProperty('MarkAsHidden')),
    },
  ]

  if (state.Filters.Ignored) {
    selections.push({
      key: 'MarkAsVisible',
      onSelect: async () =>
        await handleAction(spacesToProperty('MarkAsVisible')),
    })
  }

  const prepareRequest = (
    requestType: string,
    visibleStatus: boolean = false,
  ) => {
    setLoading(true)
    const filters = state.Filters

    // NOTE: doing this because the response column name is different than the request property
    let char = filters.SortColumn[0]
    char = char.toUpperCase()
    filters.SortColumn = filters.SortColumn.substring(1)
    filters.SortColumn = char + filters.SortColumn

    const upperCaseSearchFilters: { [key: string]: any } = {} // NOTE: will need to find out filters.Config value types to update the any type
    for (const [key, value] of Object.entries(filters.Config)) {
      char = key[0]
      char = char.toUpperCase()
      const tempKey = char + key.substring(1)
      upperCaseSearchFilters[tempKey] = value
    }

    const request: MessageRequest = {
      startDate: dateRange.dates[0],
      endDate: dateRange.dates[1],
      range: filters.PageSize,
      offset: filters.PageSize * (filters.CurrentPage - 1),
      sortColumn: filters.SortColumn,
      sortDirection: filters.SortDirection,
      exactFilters: filters.ExactFilters,
      searchFilters: upperCaseSearchFilters,
      searchText: filters.Search,
    }

    switch (requestType) {
      case 'GET_MESSAGES':
        request.ignored = state.Filters.Ignored
        break
      case 'UPDATE_MESSAGES':
        request.ignored = visibleStatus
        request.messageIds = state.SelectedIds
        request.processAll = state.SelectAll
        break
    }

    return request
  }

  const getMessages = async () => {
    const request = prepareRequest('GET_MESSAGES')
    const { data } = await api.getMessages(request)

    const copy = { ...state }
    if (data) {
      copy.Total = data.filteredMessages
      copy.Data = data.messages
      copy.OriginalData = data.messages
      localStorage.setItem(
        'trellis-last-messages-call-time',
        new Date().toString(),
      )
      localStorage.setItem(
        'trellis-total-unread-messages',
        data.totalUnreadMessages,
      )
      setTotalUnreadMessages(data.totalUnreadMessages)
    } else
      showMessage(
        'There was an error getting your messages. Please try again later.',
      )

    setState(copy)
    setLoading(false)
  }

  const updateVisibility = async (hideMessages: boolean) => {
    const request = prepareRequest('UPDATE_MESSAGES', hideMessages)
    const { data } = await api.updateMessages(request)

    const copy = { ...state }
    if (data) {
      copy.Total = data.filteredMessages
      copy.Data = data.messages
      copy.OriginalData = data.messages
    } else
      showMessage(
        'There was an error updating the visibility of your messages. Please try again later.',
      )

    setState(copy)
    setLoading(false)
  }

  const searchTable = (value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.Search = value
    copy.Filters.CurrentPage = 1
    setState(copy)
  }

  const viewMessage = async (row: any) => {
    if (!row.messageRead) {
      const { data } = await api.updateMessage(row.messageID)
      if (data) row.messageRead = data.success
      localStorage.setItem(
        'trellis-total-unread-messages',
        (totalUnreadMessages - 1).toString(),
      )
      setTotalUnreadMessages(totalUnreadMessages - 1)
    }

    const selected = state.Data.find(
      (message: any) => message.messageID === row.messageID,
    )
    setMessage(selected)
  }

  const onSearch = (event: any) => {
    setInitialLoad(false)
    setSearch(event.target.value || '')
  }

  const formatUnreadValue = (value: string, row: any) => {
    const className = !row.messageRead ? 'unread-message' : ''
    return <span className={className}>{value}</span>
  }

  const handleAction = async (value: string) => {
    switch (value) {
      case 'Mark As Hidden':
        await updateVisibility(true)
        unselectAll()
        break
      case 'Mark As Visible':
        await updateVisibility(false)
        unselectAll()
        break
    }
  }

  const unselectAll = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.SelectAll = false
    copy.SelectedIds = []
    copy.SelectedGridKeys = []
    setState(copy)
    store.removeItem('grid-filter-select-all')
  }

  const toggleIgnore = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.Ignored = !state.Filters.Ignored
    setState(copy)
  }

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    if (state) {
      const stateCopy = { ...state }
      stateCopy.Key = ++stateCopy.Key
      stateCopy.Filters.CurrentPage = 1
      setState(stateCopy)
    }
  }

  const handleDateRangeSelect = (
    dates: DateRange['dates'],
  ) => setDateFilter(dates)

  const columns = [
    {
      title: 'From',
      dataIndex: 'messageFrom',
      noFilter: true,
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.messageFrom.localeCompare(b.messageFrom),
      render: (value: string, row: any) => formatUnreadValue(value, row),
    },
    {
      title: 'Message',
      dataIndex: 'messageSubject',
      noFilter: true,
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a.messageSubject.localeCompare(b.messageSubject),
      render: (value: string, row: any) => {
        const subject = formatUnreadValue(value, row)
        return <>{subject}</>
      },
    },
    {
      title: 'Category',
      dataIndex: 'messageCategory',
      noFilter: false,
      type: 'select',
      width: 150,
      options: [
        { text: 'Attachment', value: 'Attachment' },
        { text: 'ERA', value: 'ERA' },
        { text: 'Report', value: 'Report' },
        { text: 'Announcement', value: 'Announcement' },
      ],
      sorter: (a: any, b: any) =>
        a?.messageCategory.localeCompare(b?.messageCategory),
      onFilter: (value: string, record: any) => record.value === value,
      render: (value: string, row: any) => formatUnreadValue(value, row),
    },
    {
      title: 'Send Date',
      dataIndex: 'messageDate',
      noFilter: true,
      width: 150,
      sorter: (a: any, b: any) =>
        getUnixTime(a.messageDate) - getUnixTime(b.messageDate),
      render: (value: string, row: any) =>
        formatUnreadValue(format(new Date(value), dateFormat), row),
    },
  ]

  return (
    <article className='page-content__container page-content__container--with-page-header messages-page'>
      <section>
        <h1 className='page-content-header__title mb-100'>Messages</h1>
        <Row
          justify='space-between'
          align='middle'
          wrap={false}
        >
          <RangePicker 
            allowClear={false}
            name='messages-range-picker'
            onChange={(dates, _dateStrings) =>
              dates[0] && handleDateRangeSelect([dates[0].toDate(), dates[1].toDate()])
            }
            presets={rangePresets}
            theme={rangeThemeConfig}
            value={dateRange?.dates && [dayjs(dateRange.dates[0]), dayjs(dateRange.dates[1])]}
          />
          <Col>
            <div className='message--toolbar'>
              <Search
                className='message-search'
                placeholder='Search'
                value={search}
                onChange={onSearch}
              />
              {state.Filters.Ignored ? (
                <EyeInvisibleOutlined
                  onClick={toggleIgnore}
                  className='grid-control-icon'
                />
              ) : (
                <EyeOutlined
                  onClick={toggleIgnore}
                  className='grid-control-icon'
                />
              )}
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <SmartTable
          className='mt-100'
          columns={columns}
          defaultSearch={{
            Attachments: '',
            ERAs: '',
            Reports: '',
            Announcement: '',
          }}
          getData={getMessages}
          isFullViewport={true}
          loading={loading}
          noFilter={false}
          rowKey='messageID'
          selections={selections}
          setDetail={viewMessage}
          setState={setState}
          state={state}
          storageKey='messages-grid-filters'
          tableClass='message-table'
        />
        {message && <MessageModal />}
      </section>
    </article>
  )
}

export default () => (
  <MessageContextProvider>
    <Messages />
  </MessageContextProvider>
)
