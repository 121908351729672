import { FC } from 'react'
import { Button, Modal } from 'antd'
import { endOfDay, intervalToDuration, startOfDay } from 'date-fns'
import dayjs from 'dayjs'

import { DateRange, rangePresets, rangeThemeConfig } from 'trellis:utilities/datePickerUtilities'
import { RangePicker } from '@vynedental/design-system'

import { RowSelectionType } from 'trellis:components/_siteWide/table/SmartTableTypes'
import { Labels } from 'trellis:constants/labels'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { SmartTable } from '../../_siteWide/table/SmartTable'
import { useClaimActionContext } from '../context/claimActionContext'
import { useClaimGridContext } from '../context/claimGridContext'
import { InstallModal } from '../controls/install/installModal'
import { TinHoldModal } from '../controls/tinHold/tinHoldModal'
import { GridToolbar } from '../controls/toolbar/gridToolbar'
import { BatchActions } from '../util/claimConstants'
import { getClaimColumns } from './claimColumns'

import DeleteClaim from 'trellis:assets/delete-claim.svg?react'
import HoldClaim from 'trellis:assets/hold-claim.svg?react'
import IgnoreWarnings from 'trellis:assets/ignore-warnings.svg?react'
import MarkHidden from 'trellis:assets/mark-hidden.svg?react'
import MarkVisible from 'trellis:assets/mark-visible.svg?react'
import ReleaseClaim from 'trellis:assets/release-claim.svg?react'
import SendClaim from 'trellis:assets/send-claim.svg?react'
import { observer } from '@legendapp/state/react'

const defaultSearch = {
  Details: '',
  TransmitDate: '',
  PatientName: '',
  SubscriberName: '',
  Carrier: '',
  Status: '',
  StatusDescription: '',
}

export const ClaimGrid: FC = observer(() => {
  const {
    ClaimState$,
    state,
    setState,
    setClaimId,
    dateRange,
    setDateRange,
    loading,
    getClaims,
    tinHolds,
    newInstall,
    setIgnoreClaim,
    authenticationLoaded,
    filterLoading,
  } = useClaimGridContext()
  const {
    handleAction,
    handleDelete,
    showingConfirmDelete,
    setShowingConfirmDelete,
  } = useClaimActionContext()

  const { filterState } = useClaimGridContext()

  const claimColumnWidths: { [column: string]: number } =
    JSON.parse(localStorage.getItem('claim-column-widths')) || {}

  const options = filterState

  const dismissDeleteModal = () => {
    setShowingConfirmDelete(false)
  }

  const setDetail = (row: any) => {
    setIgnoreClaim(row.Ignored)
    setClaimId(row.ClaimID)
    ClaimState$.showClaimDetail.set(true)
  }

  const selections: RowSelectionType[] = []

  if (state.ActiveTab === 'Unsent') {
    selections.push({
      key: BatchActions.SEND,
      onSelect: async () => await handleAction(BatchActions.SEND),
      icon: <SendClaim />,
    })
    selections.push({
      key: BatchActions.HOLD,
      onSelect: async () => await handleAction(BatchActions.HOLD),
      icon: <HoldClaim />,
    })
    selections.push({
      key: BatchActions.RELEASE,
      onSelect: async () => await handleAction(BatchActions.RELEASE),
      icon: <ReleaseClaim />,
    })
    selections.push({
      key: BatchActions.IGNORE_WARNINGS,
      onSelect: async () => await handleAction(BatchActions.IGNORE_WARNINGS),
      icon: <IgnoreWarnings />,
    })
    selections.push({
      key: BatchActions.DELETE,
      onSelect: async () => await handleAction(BatchActions.DELETE),
      icon: <DeleteClaim />,
    })
  } else {
    selections.push({
      key: BatchActions.HIDE,
      onSelect: async () => await handleAction(BatchActions.HIDE),
      icon: <MarkHidden />,
    })
    if (state.Filters.Ignored)
      selections.push({
        key: BatchActions.UNHIDE,
        onSelect: async () => await handleAction(BatchActions.UNHIDE),
        icon: <MarkVisible />,
      })
  }

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    if (state) {
      const stateCopy = { ...state }
      stateCopy.Key = ++stateCopy.Key
      stateCopy.Filters.CurrentPage = 1
      setState(stateCopy)
    }
  }

  const handleDateRangeSelect = (
    dates: DateRange['dates'],
  ) => setDateFilter(dates)

  const deleteModal = (
    <Modal
      title={Labels.delete}
      open={showingConfirmDelete}
      onCancel={dismissDeleteModal}
      width={600}
      footer={[
        <>
          <Button
            onClick={dismissDeleteModal}
            data-testid='cancelDeleteButton'
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            type='primary'
            loading={loading}
            data-testid='confirmDeleteButton'
          >
            {`Delete ${state.SelectedCount || 1} Record(s)`}
          </Button>
        </>,
      ]}
    >
      Are you sure you want to delete?
    </Modal>
  )

  const dateRangePicker = (
    <RangePicker
      allowClear={false}
      name='claim-grid-sent-range-picker'
      onChange={(dates, _dateStrings) => {
        handleDateRangeSelect([dates[0].toDate(), dates[1].toDate()])
      }}
      presets={rangePresets}
      theme={rangeThemeConfig}
      value={dateRange?.dates && [dayjs(dateRange.dates[0]), dayjs(dateRange.dates[1])]}
    />
  )

  const smartTable = (
    <SmartTable
      state={state}
      setState={setState}
      defaultSearch={defaultSearch}
      loading={loading}
      columns={getClaimColumns(state, options)}
      getData={getClaims}
      isFullViewport={true}
      setDetail={setDetail}
      rowKey='ClaimID'
      tableClass='claim-table'
      selections={selections}
      toolbar={<GridToolbar />}
      datePicker={
        state.ActiveTab === 'Sent' || RoleHelper$.Claims.HideGridTabs.get()
          ? dateRangePicker
          : null
      }
      filterLoading={filterLoading}
      allowColumnResize={true}
      customColumnStorage='claim-column-widths'
      customColumnWidths={claimColumnWidths}
      showBatchActionControls
      hideFilterTags={state?.Filters?.Config['Status']}
    />
  )

  return (
    <>
      {authenticationLoaded && smartTable}
      {tinHolds.length > 0 && <TinHoldModal />}
      {newInstall && <InstallModal />}
      {deleteModal}
    </>
  )
})
