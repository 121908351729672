import { CaptureConsole, ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { addSentryEventProcessor } from 'utils'

import trellisConfiguration from 'trellis:utilities/config.ts'
import { setupConsoleErrorOverride } from 'trellis:utilities/consoleHelper.ts'
import { getEmptyLdContext } from 'trellis:utilities/ldHelper.ts'
import { initializePendo } from 'trellis:utilities/pendoHelper.tsx'

import App from './App.tsx'
import './index.scss'

// DayJS Plugin Config that is needed for the Ant Design date picker
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

declare const pendo

setupConsoleErrorOverride()
;(async () => {
  Sentry.init({
    enabled: trellisConfiguration.sentry_enabled ?? false,
    dsn: trellisConfiguration.sentry_url,
    environment: trellisConfiguration.sentry_environment,
    release: `${trellisConfiguration.version}`,
    dist: `${trellisConfiguration.dist}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
      new ExtraErrorData(),
    ],
    // Performance Monitoring
    tracesSampleRate: trellisConfiguration.sentry_traceSampleRate,
  })
  addSentryEventProcessor()

  initializePendo()

  const LDProvider = await asyncWithLDProvider({
    clientSideID: trellisConfiguration.ldKey,
    context: getEmptyLdContext(),
    options: {
      streaming: true,
      logger: basicLogger({ destination: console.debug }),
    },
    reactOptions: {
      sendEventsOnFlagRead: false,
    },
  })

  ReactDOM.createRoot(document.getElementById('root')).render(
    <LDProvider>
      <App />
    </LDProvider>,
  )
})()
