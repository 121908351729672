import { Col, Row } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'

import { DatePicker, RangePickerProps } from '@vynedental/design-system'
import { AttachmentDatePickerConstants } from 'trellis:components/attachmentsShared/shared/sharedConstants'

import './imageDateTaken.scss'
import dayjs, { Dayjs } from 'dayjs'
import { themeConfig } from 'trellis:utilities/datePickerUtilities'

type ImageDateTakenProps = {
  setImageDate: (value: SetStateAction<Date>) => void
  imageDate: Date
}
const ImageDateTaken: FC<ImageDateTakenProps> = ({
  setImageDate,
  imageDate,
}) => {
  const [imageDateTaken, setImageDateTaken] = useState<Date>()

  useEffect(() => {
    if (imageDate) {
      onImageDateChange(dayjs(imageDate))
    } else {
      onImageDateChange(dayjs())
    }
  }, [])

  const onImageDateChange = (date: Dayjs) => {
    const parsedDate = date?.toDate()
    setImageDateTaken(parsedDate)
    setImageDate(parsedDate)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day') || current < dayjs(AttachmentDatePickerConstants.MINIMUM_DISABLED_DATE)
  }

  return (
    <Row className='mb-425'>
      <Col>
        <h4 className='image-date-taken__title'>Date Taken</h4>
        <DatePicker 
          disabledDate={disabledDate}
          name='claim-attachment-image-date-picker'
          onChange={onImageDateChange}
          placeholder='MM/DD/YYYY'
          theme={themeConfig}
          value={imageDateTaken && dayjs(imageDateTaken)}
        />
      </Col>
    </Row>
  )
}

export default ImageDateTaken
