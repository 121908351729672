import { useEffect } from 'react'

import { Filter, LoadingSpinner, SearchBox } from '..'
import { DataTableColumnHeader } from './components/DataTableColumnHeader'
import { DataTablePagination } from './components/DataTablePagination'
import { DataTableProps } from './utils/data-table-types'
import { RangePicker } from '@vynedental/design-system'
import styles from './DataTable.module.scss'
import { observer } from '@legendapp/state/react'
import dayjs, { Dayjs } from 'dayjs'

// TODO: needs to be able to do self pagination if backend doesn't
export const DataTable = observer(function DataTable<T>({
  config$,
  data$,
  dateRange$ = null,
  filterBy = null,
  isLoading,
  searchPlaceholder = '',
  shouldFillViewport = false,
}: DataTableProps<T>) {
  const config = config$.get()
  const columns = config.columns
  const data = data$.get()

  useEffect(() => {
    if (shouldFillViewport) handleResizeObserver()
  }, [])

  const defaultRange = dateRange$.defaultRange.peek()
  const presets = dateRange$.options.peek()

  const handleResizeObserver = () => {
    const mainPageContentElement: HTMLElement = document.querySelector(
      '.main__page-content',
    )
    if (mainPageContentElement) resizeObserver.observe(mainPageContentElement)

    return () => resizeObserver.unobserve(mainPageContentElement)
  }

  const resizeObserver = new ResizeObserver(() => {
    const tableElement: HTMLElement = document.querySelector(
      '#vyne-data-table-body',
    )
    if (tableElement) {
      const root: HTMLElement = document.querySelector(':root')
      const scrollHeight =
        window.innerHeight -
        (Math.round(tableElement.getBoundingClientRect().top) + 75)

      root?.style?.setProperty(
        '--data-table-height',
        scrollHeight.toString() + 'px',
      )
    }
  })

  const organizeData = (rowData: T, rowIndex: number): JSX.Element => {
    const columnRenders = columns.map((c, i) => (
      <td
        className={styles['vyne-data-table__body-column']}
        key={`row-${i}-${c.dataField}`}
        data-testid={`data-table-column-${c.dataField}`}
      >
        {c.render
          ? c.render(rowData[c.dataField], rowData)
          : rowData[c.dataField]}
      </td>
    ))
    return (
      <tr
        className={styles['vyne-data-table__body-row']}
        key={`data-table-row-${rowIndex}`}
        data-testid={`data-table-row-${rowIndex}`}
      >
        {columnRenders.map((r) => r)}
      </tr>
    )
  }

  const handleSearch = (value: string) => {
    config$.searchText.set(value)
    config$.pagination.currentPage.set(1)
  }

  const handleDateChange = (dates: [Dayjs, Dayjs], datestrings: [string, string]) => {
    const dataField = dateRange$.dataField.peek()

    // dates is 'null' when the 'All Time' range filter was selected, which clears the date filter
    if (dates === null) {
      config$.filters[dataField].delete()
      config$.pagination.currentPage.set(1)
    } else {


      config$.filters[dateRange$.dataField.peek()].set(`${datestrings[0]}, ${datestrings[1]}`)
      config$.pagination.currentPage.set(1)
    }
  }

  const handleFilterSelect = (filterChoice: string) => {
    if (!filterChoice || filterChoice == 'Reset') {
      config$.filters[filterBy.dataField].delete()
      config$.pagination.currentPage.set(1)
    } else config$.filters[filterBy.dataField].set(filterChoice)
    config$.pagination.currentPage.set(1)
  }

  const rangeThemeConfig = {
    primaryColor : 'var(--primary-lime, #91c73d)',
    startCircleBg : 'var(--secondary-main, #1A4A93)',
    hoverBg : 'var(--primary-100, #E7F2D4)',
    textColor : 'var(--character-primary, #333)'
  }

  return (
    <div className={styles['vyne-data-table']}>
      <div className={styles['vyne-data-table__toolbar']}>
        {searchPlaceholder && (
          <SearchBox
            onSearch={handleSearch}
            placeholder={searchPlaceholder}
          />
        )}
        {dateRange$ && (
          <div className={styles['vyne-data-table__date-range']}>
            <RangePicker 
              allowClear={false}
              defaultValue={[dayjs(defaultRange[0]), dayjs(defaultRange[1])]}
              name={dateRange$.name.peek()}
              onChange={(dates, dateStrings) => 
                handleDateChange(dates, dateStrings)
              }
              presets={presets}
              theme={rangeThemeConfig}
            />
          </div>
        )}
        {filterBy && (
          <Filter
            filterByOptions={filterBy.options}
            handleFilterSelect={handleFilterSelect}
            getStatusOverride={filterBy.getStatusOverride}
          ></Filter>
        )}
      </div>
      <div className={styles['vyne-data-table__header-wrapper']}>
        <table>
          <thead className={styles['vyne-data-table__header']}>
            <tr>
              {columns.map((c, i) => (
                <th key={`column-${c.dataField}-${i}`}>
                  <DataTableColumnHeader
                    filters$={config$.filters}
                    index={i}
                    sortBy$={config$.sortBy}
                    {...c}
                  />
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data.length ? (
            <>
              <div
                className={styles['vyne-data-table__body-wrapper']}
                id='vyne-data-table-body'
              >
                <table>
                  <tbody className={styles['vyne-data-table__body']}>
                    {data.map((d, i) => {
                      return organizeData(d, i)
                    })}
                  </tbody>
                </table>
              </div>
              {config.pagination && (
                <DataTablePagination pagination$={config$.pagination} />
              )}
            </>
          ) : (
            <div className={styles['vyne-data-table__no-data']}>No Data</div>
          )}
        </>
      )}
    </div>
  )
})
