import { observer, useObservable } from '@legendapp/state/react'
import { Checkbox, Input, Radio, RadioChangeEvent, Select } from 'antd'

import { DatePicker } from '@vynedental/design-system'

import { stateSelectOptions } from 'utils'

import { useClaimDetailContext } from 'trellis:components/claims/context/claimDetailContext'
import { PlacesOfTreatment } from 'trellis:constants/general'

import { getAncillaryClaimState } from './claimProceduresHelpers'

import '../../content/ProceduresContent.scss'

import { FormRadioInput } from 'trellis:components/_siteWide/form/formRadioInput'
import { FormSelect } from 'trellis:components/_siteWide/form/formSelect'
import dayjs from 'dayjs'
import { themeConfig } from 'trellis:utilities/datePickerUtilities'

const signatureText = 'SIGNATURE'

const AncillaryClaimControls = observer(() => {
  const { claim, setClaim } = useClaimDetailContext()

  const state = useObservable(getAncillaryClaimState(claim))

  const handleChangeReplacementOfProsthesis = (e: RadioChangeEvent) => {
    const prosthesisReplaced = e.target.value
    state.replacementOfProsthesis.set(prosthesisReplaced)
    setClaim({
      ...claim,
      PROSTHESIS_INITIAL_PLACEMENT: prosthesisReplaced === 'Y' ? 'N' : 'Y',
    })

    prosthesisReplaced === 'Y'
      ? state.placementDateDisabled.set(false)
      : state.placementDateDisabled.set(true)
  }

  const handleChangeForOrthodontics = (e: RadioChangeEvent) => {
    state.forOrthodontics.set(e.target.value)
    setClaim({ ...claim, ORTHO_FLAG: e.target.value })

    e.target.value === 'Y'
      ? state.orthoDateDisabled.set(false)
      : state.orthoDateDisabled.set(true)
  }

  const handleProviderInfoChange = (e: RadioChangeEvent) => {
    switch (e.target.name) {
      case 'release of info':
        state.releaseOfInformation.set(e.target.value)
        setClaim({ ...claim, SIGNATURE: e.target.value })
        break
      case 'AssignmentOfPayment':
        state.assignmentOfPayment.set(e.target.value)
        setClaim({ ...claim, ASSIGNMENT_SIGNATURE: e.target.value })
        break
      default:
        break
    }
  }

  const handleChangeResultingFrom = (e) => {
    const checkedValue = e?.target?.value

    if (checkedValue === state.accidentType.peek()) {
      state.accidentType.set('NONE')
      state.accidentDetailsDisabled.set(true)
      setClaim({ ...claim, ACCIDENT_TYPE: checkedValue })
    } else {
      state.accidentType.set(checkedValue)
      setClaim({ ...claim, ACCIDENT_TYPE: checkedValue })

      switch (checkedValue) {
        case 'AUTO':
        case 'OTHER':
          state.accidentDetailsDisabled.set(false)
          break
        case 'WORK':
        case 'NEITHER':
          state.accidentDetailsDisabled.set(true)
          break
        default:
          state.accidentDetailsDisabled.set(true)
          break
      }
    }
  }

  return (
    <div className='ancillary-claim-controls'>
      <div className='ancillary-claim-controls__row--treatment'>
        <p className='ancillary-claim-controls__label-bold-select'>
          Place of Treatment
        </p>
        <FormSelect
          name='PLACE_OF_TREATMENT'
          label='Place of Treatment'
          initialValue={claim.PLACE_OF_TREATMENT}
          element={claim}
          setElement={setClaim}
          options={PlacesOfTreatment}
          required
        />
      </div>
      <div className='ancillary-claim-controls__row--prosthesis'>
        <div>
          <p className='ancillary-claim-controls__label-bold'>
            Replacement of Prosthesis
          </p>
          <Radio.Group
            onChange={handleChangeReplacementOfProsthesis}
            value={state.replacementOfProsthesis.get()}
          >
            <Radio value='N'>No</Radio>
            <Radio value='Y'>Yes</Radio>
          </Radio.Group>
        </div>
        <div>
          <p className='ancillary-claim-controls__label'>Placement Date</p>
          <DatePicker
            disabled={state.placementDateDisabled.get()}
            defaultValue={
              claim?.PRIOR_PLACEMENT_DATE &&
              dayjs(claim.PRIOR_PLACEMENT_DATE)
            }
            name='prior-placement-date-picker'
            onChange={(date) =>
              setClaim({
                ...claim,
                PRIOR_PLACEMENT_DATE: date?.toLocaleString(),
                PRIOR_PLACEMENT_DATE_String: date?.toLocaleString(),
              })
            }
            placeholder='Select date'
            theme={themeConfig}
          />
        </div>
      </div>
      <div className='ancillary-claim-controls__row--ortho'>
        <div>
          <p className='ancillary-claim-controls__label-bold'>
            Is Treatment for Orthodontics?
          </p>
          <Radio.Group
            onChange={handleChangeForOrthodontics}
            value={state.forOrthodontics.get()}
          >
            <Radio value='N'>No</Radio>
            <Radio value='Y'>Yes</Radio>
          </Radio.Group>
        </div>
        <div>
          <p className='ancillary-claim-controls__label'>
            Date Appliance Placed
          </p>
          <DatePicker
            disabled={state.orthoDateDisabled.get()}
            defaultValue={
              claim?.APPLIANCE_DATE && dayjs(claim.APPLIANCE_DATE)
            }
            name='date-of-appliance-date-picker'
            onChange={(date) =>
              setClaim({
                ...claim,
                APPLIANCE_DATE: date.toLocaleString(),
                APPLIANCE_DATE_String: date.toLocaleString(),
              })
            }
            placeholder='Select date'
            theme={themeConfig}
          />
        </div>
        <div>
          <p className='ancillary-claim-controls__label'>Treatment Remaining</p>
          <div className='ancillary-claim-controls--treatment-remaining'>
            <Input
              defaultValue={claim.TREATMENT_MONTHS}
              disabled={state.orthoDateDisabled.get()}
              type='number'
              style={{ width: '3em' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setClaim({ ...claim, TREATMENT_MONTHS: e.target.value })
              }
            />
            <span>Months</span>
          </div>
        </div>
      </div>
      <div>
        <p className='ancillary-claim-controls__label-bold-select'>
          Treatment resulting from? (Select One)
        </p>
        <div className='ancillary-claim-controls__row'>
          <div className='ancillary-claim-controls__row--resulting-from'>
            <Checkbox
              checked={state.accidentType.get() === 'WORK'}
              onChange={handleChangeResultingFrom}
              value='WORK'
            >
              <p className='ancillary-claim-controls__label'>
                Occupational Illness/Injury
              </p>
            </Checkbox>
            <Checkbox
              checked={state.accidentType.get() === 'OTHER'}
              onChange={handleChangeResultingFrom}
              value='OTHER'
            >
              <p className='ancillary-claim-controls__label'>Other Accident</p>
            </Checkbox>
          </div>
          <div className='ancillary-claim-controls__row--resulting-from'>
            <Checkbox
              checked={state.accidentType.get() === 'AUTO'}
              onChange={handleChangeResultingFrom}
              value='AUTO'
            >
              <p className='ancillary-claim-controls__label'>Auto Accident</p>
            </Checkbox>
            <Select
              defaultValue={claim.ACCIDENT_ST}
              options={stateSelectOptions}
              disabled={state.accidentDetailsDisabled.get()}
              onSelect={(value: string) =>
                setClaim({ ...claim, ACCIDENT_ST: value })
              }
              placeholder='Accident State'
              style={{ maxWidth: '135px' }}
            />
            <DatePicker
              disabled={state.accidentDetailsDisabled.get()}
              defaultValue={
                claim?.ACCIDENT_DATE && dayjs(claim.ACCIDENT_DATE)
              }
              name='date-of-accident-date-picker'
              onChange={(date) =>
                setClaim({
                  ...claim,
                  ACCIDENT_DATE: date.toLocaleString(),
                  Accident_Date_String: date.toLocaleString(),
                })
              }
              placeholder='Date of Accident'
              theme={themeConfig}
            />
          </div>
        </div>
      </div>
      <div className='ancillary-claim-controls__row--signature'>
        <div>
          <p className='ancillary-claim-controls__label-bold'>
            Release of information by provider?
          </p>
          <Radio.Group
            onChange={handleProviderInfoChange}
            value={
              state.releaseOfInformation
                .get()
                ?.toUpperCase()
                .includes(signatureText)
                ? 'SIGNATURE ON FILE'
                : null
            }
            name='release of info'
          >
            <Radio value='SIGNATURE ON FILE'>Yes</Radio>
            <Radio value={null}>No</Radio>
          </Radio.Group>
        </div>
        <div>
          <p className='ancillary-claim-controls__label-bold'>
            Assignment of payment to provider?
          </p>
          <FormRadioInput
            name='AssignmentOfPayment'
            onChange={handleProviderInfoChange}
            options={[
              { value: 'SIGNATURE ON FILE', text: 'Yes' },
              { value: null, text: 'No' },
            ]}
            initialValue={
              state.assignmentOfPayment
                .get()
                ?.toUpperCase()
                .includes(signatureText)
                ? 'SIGNATURE ON FILE'
                : null
            }
            element={claim}
            setElement={setClaim}
          />
        </div>
      </div>
    </div>
  )
})

export default AncillaryClaimControls
