import { Exception } from '@sentry/react'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { LogError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import { isTrellisAuthExpired } from '../features/authentication/Login/utils/login-helpers'
import trellisConfiguration from './config'

const webSocketApi: string = trellisConfiguration.websocket_apiUrl

const SocketContext = createContext(null)

const getWebSocketState = (readyState: number) => {
  const stateMap: { [key: number]: string } = {
    0: 'CONNECTING',
    1: 'OPEN',
    2: 'CLOSING',
    3: 'CLOSED',
  }
  return stateMap[readyState] || 'UNKNOWN'
}

export const SocketContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  let retries = 0
  const maxRetries = 5
  let attemptingConnection = false

  const onOpen = () => {
    console.debug('websocket connected')
    ws.current.addEventListener('close', onClose)
  }

  const onClose = () => {
    console.debug('websocket disconnected')

    if (isTrellisAuthExpired()) {
      console.debug('auth token expired, not reconnecting')
      return
    } else if (attemptingConnection) {
      console.debug('already attempting connection')
      return
    }

    verifyConnection()
  }

  const onError = (event: Event) => {
    try {
      const messageText = JSON.stringify(event)
      const error = new Error('WebSocket Error')
      LogError(error, messageText)
    } catch (e) {
      LogError(e, 'Error logging websocket error')
    }
  }

  const handleHeartbeat = () => {
    const intervalId = setInterval(() => {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ action: 'heartbeat' }))
      }
    }, 300000) // Send heartbeat every 5 minutes

    return () => clearInterval(intervalId)
  }

  const newWebSocket = () => {
    const authToken = encodeURIComponent(GlobalState.Auth.AuthToken.peek())
    const registrationToken = encodeURIComponent(
      GlobalState.Auth.RegistrationToken.peek(),
    )
    const webSocketUrl = `${webSocketApi}?authtoken=${authToken}&registrationtoken=${registrationToken}`

    return new WebSocket(webSocketUrl)
  }

  function getRandomExponentialTime() {
    // Generate a random number between 0 and 1
    const randomFactor = Math.random()

    // Apply an exponential function to skew the distribution
    const exponentialFactor = Math.pow(randomFactor, 1.5) // Adjust the exponent to control the skewness

    // Scale the result to the desired range (2 to 5 seconds)
    const randomSeconds = 2 + exponentialFactor * 5

    // Convert the seconds to milliseconds
    const randomMilliseconds = randomSeconds * 1000

    return randomMilliseconds
  }

  const verifyConnection = async (): Promise<boolean> => {
    console.debug('verifying connection')

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      console.debug('connection status: OPEN')
      return true
    }

    attemptingConnection = true

    const retryConnection = async () => {
      console.debug('connection invalid, retry attempt: ' + retries)
      try {
        ws.current = newWebSocket()
      } catch (error) {
        console.debug('failed to create new websocket connection')
      }
    }

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms))

    let socketIsOpen: boolean = false

    while (!socketIsOpen && retries++ < maxRetries) {
      await retryConnection()
      await delay(getRandomExponentialTime())
      socketIsOpen = ws.current && ws.current.readyState === WebSocket.OPEN
    }

    console.debug(
      'connection status: ' + getWebSocketState(ws.current.readyState),
    )

    attemptingConnection = false
    retries = 0

    if (socketIsOpen) {
      ws.current.addEventListener('open', onOpen)
      ws.current.addEventListener('close', onClose)
      ws.current.addEventListener('error', onError)

      handleHeartbeat()
    } else {
      const error = new Error('WebSocket Error')
      LogError(error, 'Websocket disconnected and failed to reconnect')
    }
    return socketIsOpen
  }

  const webSocket = useMemo(() => newWebSocket(), [])
  const ws = useRef<WebSocket>(webSocket)

  useEffect(() => {
    handleHeartbeat()

    ws.current.addEventListener('open', onOpen)
    ws.current.addEventListener('error', onError)
    ws.current.removeEventListener('close', onClose)

    return () => {
      ws.current.removeEventListener('open', onOpen)
      ws.current.removeEventListener('error', onError)
      ws.current.removeEventListener('close', onClose)
    }
  }, [])

  return (
    <SocketContext.Provider value={{ socket: ws, verifyConnection }}>
      {children}
    </SocketContext.Provider>
  )
}

export const useSocketContext = () => {
  const context = useContext(SocketContext)

  return context
}
